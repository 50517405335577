import React, { createContext, useState, useEffect } from 'react';

// Create a Context for authentication
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [loggedInUser, setLoggedInUser] = useState(null);
    const [highscore, setHighscore] = useState(0); // Add state for highscore

    useEffect(() => {
        // Check if the user data and highscore are stored in localStorage
        const storedUser = localStorage.getItem('user');
        const storedHighscore = localStorage.getItem('highscore');

        if (storedUser) {
            setLoggedInUser(JSON.parse(storedUser));
        }

        if (storedHighscore) {
            setHighscore(parseInt(storedHighscore, 10)); // Ensure the highscore is a number
        }
    }, []);

    const loginUser = (userData) => {
        setLoggedInUser(userData);
        localStorage.setItem('user', JSON.stringify(userData)); // Store user data in localStorage
    };

    const logoutUser = () => {
        setLoggedInUser(null);
        setHighscore(0); // Reset highscore when logging out
        localStorage.removeItem('user'); // Remove user data from localStorage
        localStorage.removeItem('highscore'); // Remove highscore from localStorage
    };

    const updateHighscore = (newHighscore) => {
        if (newHighscore > highscore) { // Only update if the new score is higher
            setHighscore(newHighscore);
            localStorage.setItem('highscore', newHighscore); // Store new highscore in localStorage
        }
    };

    return (
        <AuthContext.Provider value={{ loggedInUser, loginUser, logoutUser, highscore, updateHighscore }}>
            {children}
        </AuthContext.Provider>
    );
};
