import React, { useState, useContext, useEffect } from 'react';
import { firestore } from './firebase-config';
import { collection, addDoc, query, where, getDocs, orderBy, limit } from 'firebase/firestore';
import bcrypt from 'bcryptjs';
import { AuthContext } from './AuthContext';
import MarkdownIt from 'markdown-it';  // Import markdown-it

// Initialize markdown-it
const md = new MarkdownIt();

function CommunityPage() {
    const { loggedInUser, loginUser, logoutUser, highscore, updateHighscore } = useContext(AuthContext);
    const [isLogin, setIsLogin] = useState(true);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [username, setUsername] = useState('');
    const [message, setMessage] = useState('');
    const [threads, setThreads] = useState([]);
    const [newThread, setNewThread] = useState('');

    const checkUsernameExists = async (username) => {
        const usersRef = collection(firestore, 'users');
        const q = query(usersRef, where('username', '==', username));
        const querySnapshot = await getDocs(q);
        return !querySnapshot.empty;
    };

    const fetchThreads = async () => {
        const threadsRef = collection(firestore, 'threads');
        const q = query(threadsRef, orderBy('createdAt', 'desc'), limit(10));
        const querySnapshot = await getDocs(q);
        const threadsData = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));
        setThreads(threadsData);
    };

    const handleCreateThread = async () => {
        if (!newThread.trim()) {
            setMessage('Please enter a thread message.');
            return;
        }

        try {
            if (!loggedInUser) {
                setMessage('You must be logged in to create a thread.');
                return;
            }

            const threadsRef = collection(firestore, 'threads');
            await addDoc(threadsRef, {
                content: newThread,
                createdAt: new Date(),
                username: loggedInUser.username,
                userId: loggedInUser.email,
            });

            setNewThread('');
            setMessage('Thread created successfully!');
            fetchThreads();
        } catch (error) {
            setMessage(`Error: ${error.message}`);
        }
    };

    const handleSignUp = async () => {
        try {
            const usernameExists = await checkUsernameExists(username);
            if (usernameExists) {
                setMessage('Username is already taken. Please choose a different one.');
                return;
            }

            const hashedPassword = await bcrypt.hash(password, 10);
            await addDoc(collection(firestore, 'users'), {
                username,
                email,
                password: hashedPassword,
                highscore: 0,
            });

            setMessage('Sign-up successful! You can now log in.');
            setIsLogin(true);
        } catch (error) {
            setMessage(`Error: ${error.message}`);
        }
    };

    const handleLogin = async () => {
        try {
            const q = query(collection(firestore, 'users'), where('email', '==', email));
            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
                setMessage('User not found.');
                return;
            }

            const userDoc = querySnapshot.docs[0];
            const userData = userDoc.data();

            const isPasswordValid = await bcrypt.compare(password, userData.password);
            if (isPasswordValid) {
                loginUser({ ...userData, id: userDoc.id });
                setMessage(`Welcome back, ${userData.username}!`);
                updateHighscore(userData.highscore);
            } else {
                setMessage('Invalid password.');
            }
        } catch (error) {
            setMessage(`Error: ${error.message}`);
        }
    };

    const handleLogout = () => {
        logoutUser();
        setMessage('Logged out successfully!');
    };

    useEffect(() => {
        fetchThreads();
    }, []);

    return (
        <div style={{ padding: '20px', maxWidth: '600px', margin: 'auto' }}>

            <p><strong>Username:</strong> {loggedInUser ? loggedInUser.username : 'Guest'}</p>

        

            {loggedInUser && (
                <>
                    {/* <button onClick={handleLogout} className='community-button-red'>Log Out</button> */}

                    <div className='post-form'>
                        <h2>Create a Thread</h2>
                        <textarea 
                            placeholder="Write your thread here"
                            value={newThread}
                            onChange={(e) => setNewThread(e.target.value)}
                            style={{ width: '100%', marginBottom: '10px' }}
                        />
                        <button onClick={handleCreateThread} style={{ width: '100%' }}>Create Thread</button>
                    </div>
                </>
            )}

            {message && <p style={{ color: 'green', marginTop: '20px' }}>{message}</p>}

            <h2>Posts</h2>
            <div style={{ maxWidth: '600px', margin: 'auto' }}>
                {threads.length > 0 ? (
                    threads.map((thread) => (
                        <div key={thread.id} style={{
                            marginBottom: '20px',
                            wordWrap: 'break-word',
                            wordBreak: 'break-word',
                            backgroundColor: '#333',
                            borderRadius: '10px',
                            padding: '10px',
                            color: 'white',
                        }}>
                            <h3>{thread.username} said:</h3>
                            <div
                                style={{ overflowWrap: 'break-word', wordWrap: 'break-word' }}
                                dangerouslySetInnerHTML={{ __html: md.render(thread.content) }}  // Render markdown content
                            />
                            <p><small>{new Date(thread.createdAt.seconds * 1000).toLocaleString()}</small></p>
                        </div>
                    ))
                ) : (
                    <p>No threads available yet.</p>
                )}
            </div>
        </div>
    );
}

export default CommunityPage;
